.dropdown-item {
    color: rgba(0, 0, 0, 0.54);
    font-size: 1rem;
    font-family: "Helvetica";
    font-weight: 400;
    line-height: 1.5;
}

.no-arrow::after {
    display: none;
}

.dropdown-toggle::after {
    display: none;
}

.navigation-icons {
    color: rgb(0, 0, 102);
    height: 22px;
    width: 22px;
    margin-right: 10px;

}

.dropdown-item {
    padding: 12px !important;

}

.emoji {
    font-size: 2rem;
    cursor: pointer;
}

.emoji-selected-1 {
    font-size: 2.3rem;
    color: rgb(183, 28, 28);
    text-shadow: 0px 0px 30px rgb(183, 28, 28);
    transition: font-size .3s;
}

.emoji-selected-2 {
    font-size: 2.3rem;
    color: rgb(244, 67, 54);
    text-shadow: 0px 0px 30px rgb(244, 67, 54);
    transition: font-size .3s;
}

.emoji-selected-3 {
    font-size: 2.3rem;
    color: rgb(255, 171, 64);
    text-shadow: 0px 0px 30px rgb(255, 171, 64);
    transition: font-size .3s;
}

.emoji-selected-4 {
    font-size: 2.3rem;
    color: rgb(0, 230, 118);
    text-shadow: 0px 0px 40px rgb(0, 230, 118);
    transition: font-size .3s;
}

.emoji-selected-5 {
    font-size: 2.3rem;
    color: rgb(46, 125, 50);
    text-shadow: 0px 0px 40px rgb(46, 125, 50);
    transition: font-size .3s;
}

.emoji-row {
    height: 3rem;
}

.invalid-text {
    color: rgb(176, 0, 32);
    font-size: 10px;
    text-align: left !important;
}

.cancel-button {
    color: rgb(255, 255, 255);
    text-transform: none;
    border-radius: 32px;
    background-color: rgb(243, 55, 0);
    border: none;
}

.save-button {
    color: rgb(255, 255, 255);
    text-transform: none;
    border-radius: 32px;
    background-color: rgb(0, 0, 102);
    border: none;
}

.form-label {
    color: #47479E;
    font-weight: bold;
}

.feedback-input input[type="email"] {

    width: 100%;
    height: 2.5rem;
    border: 1px solid #dfdfdf
}

.feedback-input input[type="text"] {

    width: 100%;
    height: 5rem;
    border: 1px solid #dfdfdf;
}


.modal-body {
    display: table-cell;
    vertical-align: middle;
    text-align: center;


}

.disclaimer {
    color: rgba(0, 0, 0, 0.54);
    font-size: 0.7rem;
    text-align: center;
    margin-top: 3px;
    margin-left: 3px;
    font-family: "Helvetica";
    font-weight: 400;
    line-height: 1.66;
}

.menu-icon {
    margin-top: 9px;
    height: 22px;
    width: 10px;
    padding: 0 !important;
    margin-left: .5rem;
    border: none !important;
}

.modal.show .modal-dialog {
    margin-top: 18vh;
}

.dropdown-menu {
    margin-top: .75rem !important;
}
.dropdown-item
{
    cursor: pointer;
}