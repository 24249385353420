.bg-container {
   background: url("background.png") center center no-repeat;
   height: 100dvh;
   overflow: hidden;
}

.webchat-container {
   height: 83dvh;
   border-radius: 1.2rem;
   max-width: 420px;
}

.webchat-header {
   height: 60px;
   width: 100%;
   border-radius: 1.2rem 1.2rem 0rem 0rem;
}

.user-detail-header {
   height: 50px;
   background-color: #fafafa;
   border-bottom: 1px solid lightgray;
}

.company-logo {
   width: 60px;
   height: 20px;
}

.user-name {
   font-size: 13px;
   color: rgb(0, 0, 102);
   font-weight: 600;
}

.login-time {
   color: rgb(0, 0, 102);
   font-size: 10px;
   font-weight: 600;
}

.chat-box {
   height: 86%;
   position: relative;
}

*:focus-visible {
   outline: none !important;
   outline-width: 0 !important;
   border: none;
}

.chat-box>*:focus {
   outline: none !important;
   outline-width: 0 !important;
   border: none;
}

.webchat__send-box__main {
   background-color: #B4C1D8 !important;
   height: 60px;
   border-radius: 0 0 1.2rem 1.2rem;
   padding: 8px 13px;
}

.webchat__upload-button {
   display: none !important;
}

.webchat__send-box__text-box {
   background-color: #fff;
   border-radius: 30px;
   border: 1px solid rgb(153, 153, 153);
   /* flex: none !important; */
   /* width: 70%; */
}

.webchat__chat {
   background-image: url("chatBackground.svg");
   background-repeat: no-repeat;
   background-size: cover;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
   display: flex;
   flex-direction: column;
   height: 100%;
   margin: auto;
   width: 420px;
   transition-duration: 0.5s;
   transition-property: max-width, min-width, width;
   border-radius: 0 0 1.2rem 1.2rem;
}

.waves-img {
   position: absolute;
   bottom: -10px;
   right: -293px;
   height: 64px;
   width: 293px;
}

.powered-by-ariya-img {
   position: absolute;
   bottom: -10px;
   right: -431px;
   height: 53px;
   width: 134px;
}

.copyright {
   font-size: 0.75rem;
   font-weight: 400;
   line-height: 1.66;
   padding: 15px;
   height: 50px;
   position: fixed;
   bottom: 10px;
   z-index: 0;
   left: 0px;
}

.webchat__icon-button__shade {
   background: #000066;
   border-radius: 50% !important;
   width: 38px;
}

.webchat__icon-button:not(:disabled):not([aria-disabled="true"]):not(:active):hover .webchat__icon-button__shade {
   background: #000066 !important;
}

.webchat__send-icon {
   fill: #fff;
}

::-webkit-scrollbar {
   width: 5px;
   background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
   background-color: #6161AA;
}

.bottom-border {
   position: fixed;
   width: 100%;
   height: 9px;
   background-color: rgb(0, 112, 192);
   bottom: 0px;
}

.webchat__send-box-text-box__input {
   width: 100%;
}

.webchat__send-box-text-box {
   width: 90%;
   display: block !important;
   flex: none !important;
}

.webchat__send-button {
   margin-left: auto;
}

.microphone-box {
   position: absolute;
   right: 17%;
   bottom: 15px;
}

.dot-box {
   position: absolute;
   right: 19%;
   bottom: 29px;
}

.microphone-icon {
   width: 20px;
   height: 30px;
   cursor: pointer;
}

@media (max-width: 720px) {
   .webchat-container {
      height: 100dvh;
      max-width: 100%;
   }

   .webchat-header {
      height: auto;
   }

   .chat-box {
      height: 81%;
   }

   .copyright,
   .powered-by-ariya-img,
   .waves-img,
   .bottom-border {
      display: none;
   }

   .webchat-header,
   .webchat__send-box__main {
      border-radius: 0px;
   }

   .bg-container {
      background-color: #B4C1D8 !important;
   }

   .webchat__send-box__main {
      position: fixed;
      bottom: 0px;
      left: 0px;
      width: 100%;
   }

   .webchat__chat {
      border-radius: 0px !important;
   }

   .microphone-box {
      bottom: 13px;
      position: fixed;
   }
}

@media (min-width: 721px) and (max-width: 1100px) {
   .chat-box {
      height: 66dvh;
   }

   .webchat-container {
      max-width: 360px;
      height: 78dvh;
   }
 
}


.dot-elastic {
   width: 6px;
   height: 6px;
   border-radius: 5px;
   background-color: #000066;
   color: #000066;
   animation: dotElastic 1s infinite linear;
   z-index: 5;
}

.dot-elastic::before,
.dot-elastic::after {
   content: '';
   display: inline-block;
   position: absolute;
   top: 0;
   z-index: 5;
}

.dot-elastic::before {
   left: -8px;
   width: 6px;
   height: 6px;
   border-radius: 5px;
   background-color: #000066;
   color: #000066;
   animation: dotElasticBefore 1s infinite linear;
}

.dot-elastic::after {
   left: 8px;
   width: 6px;
   height: 6px;
   border-radius: 5px;
   background-color: #000066;
   color: #000066;
   animation: dotElasticAfter 1s infinite linear;
}

@keyframes dotElasticBefore {
   0% {
      transform: scale(1, 1);
   }

   25% {
      transform: scale(1, 1.5);
   }

   50% {
      transform: scale(1, 0.67);
   }

   75% {
      transform: scale(1, 1);
   }

   100% {
      transform: scale(1, 1);
   }
}

@keyframes dotElastic {
   0% {
      transform: scale(1, 1);
   }

   25% {
      transform: scale(1, 1);
   }

   50% {
      transform: scale(1, 1.5);
   }

   75% {
      transform: scale(1, 1);
   }

   100% {
      transform: scale(1, 1);
   }
}

@keyframes dotElasticAfter {
   0% {
      transform: scale(1, 1);
   }

   25% {
      transform: scale(1, 1);
   }

   50% {
      transform: scale(1, 0.67);
   }

   75% {
      transform: scale(1, 1.5);
   }

   100% {
      transform: scale(1, 1);
   }
}