.modal-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.modal-header-and-title{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.modal-header-image{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}
.modal-close-btn{
    background-color: white;
    cursor: pointer;
}
.modal-close-btn>img{
    width: 20px;
    height: 20px;
}

.modal-close-btn:hover{
    background-color: #f1f1f1;
}
.modal-open-btn{
    background-color: #f1f1f1;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    color: #0000EE;
    text-decoration: underline;
}
.modal-open-btn:hover{
    background-color: #e1e1e1;
}